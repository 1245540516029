<template>
  <!-- <v-col cols="12" :md="size === 2 ? 6 : size === 3 ? 4 : undefined"> -->
  <v-col cols="12" md="6">
    <!-- <base-card :height="value.prominent ? 450 : 350" color="grey lighten-1" dark href="#!"> -->
    <v-hover v-slot:default="{ hover }">
      <v-card
        height="350"
        color="grey lighten-1"
        dark
        :href="`/speakers/${speaker.slug}`"
        class="mx-auto theme--light speakerCard"
        :elevation="hover ? 28 : 15"
      >
        <!-- <v-img
        :src="require(`@/assets/articles/${value.hero}`)"
        height="100%"
        gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
        >-->
        <v-img
          :src="`${speaker.downloadImgUrl}`"
          height="100%"
          gradient="rgba(0, 0, 0, .32), rgba(0, 0, 0, .32)"
        >
          <v-row v-if="!speaker.prominent" class="fill-height text-left ma-0">
            <v-col cols="12" align-self="end">
              <v-chip
                label
                class="mx-0 mb-2 text-uppercase"
                color="grey darken-3"
                text-color="white"
                :href="`/category/${speaker.areas_of_expertise[0]}`"
                small
                @click.stop
                v-if="speaker.areas_of_expertise"
                >{{ speaker.areas_of_expertise[0] }}</v-chip
              >

              <h3 class="title font-weight-bold mb-1">
                {{ speaker.first_name }} {{ speaker.last_name }}
              </h3>

              <div class="caption mb-3">{{ speaker.role }}</div>
            </v-col>

            <!-- <v-col align-self="end">
              <v-chip class="text-uppercase ma-0" color="primary" label big
                >VEDI ORA</v-chip
              >
            </v-col> -->
          </v-row>
        </v-img>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  name: "FeedCard",

  props: {
    speaker: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.v-image__image {
  transition: 0.3s linear;
}
.speakerCard {
  background-color: black !important;
}
.speakerCard:hover {
  border: 1px solid rgb(255, 255, 255, 0.1) !important;
  background-color: #212121 !important;
}
</style>
